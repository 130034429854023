import React, { useState } from 'react';
import CardImage from '../CardImage';
import useMatchMedia from '../../hooks/match-media';

const ListCardsImage = ({ cards, isFeed }) => {
  /** Media rule to match on resize */
  const mediaToMatch = '(max-width: 1023px)';
  const [isMobile, setIsMobile] = useState(false);
  /** Using external HOOKS to listen window resize */
  useMatchMedia(setIsMobile, mediaToMatch);
  return (
    <>
      {
        cards && cards.map((card, index) => {
          const {
            title,
            topic,
            id,
            coverImage,
            slug
          } = card;
          const { date } = { date: card.publicationDate };
          const { categoryNews } = card.topic;
          if (isFeed) {
            return (
              <div key={`${id}-${index}`} className={`${isMobile && isFeed ? 'swiper-slide feed__slide' : 'feed__slide--static'}`}>
                <CardImage
                  date={date}
                  title={title}
                  label={topic.name}
                  image={coverImage && coverImage.fluid}
                  alt={coverImage && coverImage.alt}
                  slug={slug}
                />
              </div>
            );
          }
          return (
            <CardImage
              slug={slug}
              key={`${id}-${index}`}
              date={date}
              title={title}
              label={topic.name}
              image={coverImage && coverImage.fluid}
              description={coverImage && coverImage.alt}
              topic={topic}
              categoryNews={categoryNews}
            />
          );
        })
      }
    </>
  );
};

export default ListCardsImage;
