import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import moment from 'moment';
import Layout from '../containers/layout';
import HeaderSingleNews from '../components/HeaderSingleNews';
import ParagraphNews from '../components/ParagraphNews';
import WidgetCard from '../components/WidgetCard';
import useMatchMedia from '../hooks/match-media';
import ReduxContainer from '../containers/redux-container';
import VisualComposer from '../components/VisualComposer';
import ListCardsImage from '../components/ListCardsImage';
import Feed from '../components/Feed';
import { filterNewsByCategory, filterNewsByTopic } from '../actions/news-filter-actions';
import Container from '../components/Container';
import { useWordCounter } from '../hooks/word-counter';
import WidgetSocial from '../components/WidgetSocial';
import Download from '../components/Download';
import StructuredData from '../components/StructuredData';

const SinglePostTemplate = ({ data, pageContext }) => {
  const {
    relatedNews,
    newsRelatedCustom,
    news,
    newsArchive
  } = {
    newsRelatedCustom: data.datoCmsNews.newsRelated[0],
    relatedNews: data.allDatoCmsNews.nodes,
    news: data.datoCmsNews,
    newsArchive: data.datoCmsNewsArchive
  };
  const { seoMetaTags } = data.datoCmsNews;
  let totalReadingTime = useWordCounter(news.paragraph);
  const allParagraphs = data.datoCmsNews.visualComposer.filter((vc) => vc['__typename'] === 'DatoCmsParagraph');
  allParagraphs.forEach((paragraph) => {
    totalReadingTime += useWordCounter(paragraph.content);
  });

  const [isMobile, setIsMobile] = useState(false);
  const mediaToMatch = '(max-width: 1365px)';
  useMatchMedia(setIsMobile, mediaToMatch);
  const srcCoverImage = (news.coverImage && news.coverImage.fluid.src) ? news.coverImage.fluid.src : '';
  const srcCoverImageMobile = (news.coverImageMobile && news.coverImageMobile.fluid.src) ? news.coverImageMobile.fluid.src : '';
  return (
    <ReduxContainer>
      <Layout
        seoMetaTags={seoMetaTags}
        pathname={pageContext.pathname}
        communicationBar={pageContext.communicationBar}
        publicationDate={moment(news.publicationDate).format('YYYY-MM-DD')}
        articleCategory={news.topic.name}
        pageType="News Page"
        canonical={pageContext.canonical}
      >
        <StructuredData>
          {{
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: news.title,
            image: [
              srcCoverImage,
              srcCoverImageMobile
            ],
            datePublished: news.publicationDate,
          }}
        </StructuredData>
        <HeaderSingleNews
          coverImage={news.coverImage}
          coverImageMobile={news.coverImageMobile}
          categoryNews={news.topic.categoryNews}
          title={news.title}
          topic={news.topic}
          time={totalReadingTime}
        />
        <div className="news-wrapper">
          {news.widgetCard
            && isMobile
            && (
              <WidgetCard
                widgetCard={news.widgetCard}
              />
            )}
          <ParagraphNews
            content={news.paragraph}
            numCols="small"
            widgetCard={news.widgetCard}
            author={news.author}
            date={moment(news.publicationDate).format('DD/MM/YYYY')}
            dropcap
          />
          {
            data && data.datoCmsNews.visualComposer && (
              <VisualComposer
                components={data.datoCmsNews.visualComposer}
              />
            )
          }
          {
            news.documents.length > 0 && news.documents && (
              <Download
                label="Documenti disponibili"
                listDocument={news.documents}
                marginTop="default"
                marginBottom="default"
                numCols="small"
                labelButton="Download"
              />
            )
          }
        </div>
        <div className="news__widget-social">
          <WidgetSocial
            author={news.author}
            date={moment(news.publicationDate).format('DD/MM/YYYY')}
          />
        </div>
        {
          (relatedNews.length > 0 || newsRelatedCustom) ? (
            <WrappedFeed
              newsRelatedCustom={newsRelatedCustom}
              newsArchive={newsArchive}
              news={news}
              relatedNews={relatedNews}
            />
          ) : (
            <Container
              marginTop="grande"
            />
          )
        }
      </Layout>
    </ReduxContainer>
  );
};

const FeedContainer = ({
  newsRelatedCustom,
  newsArchive,
  relatedNews,
  dispatch,
  news
}) => {
  const handleArchiveClick = () => {
    dispatch(filterNewsByCategory({
      label: news.topic.categoryNews.label,
      value: news.topic.categoryNews.slug
    }));
    dispatch(filterNewsByTopic({
      label: news.topic.label,
      value: news.topic.slug
    }));
  };

  if (newsRelatedCustom && newsRelatedCustom.post[0]) {
    return (
      <Feed
        marginTop="zero"
        marginBottom="zero"
        title={newsRelatedCustom.title}
        labelLink={newsRelatedCustom.labelLink}
        url={newsArchive.slug}
        hasBackground
        hasPadding
        handleArchiveClick={handleArchiveClick}
      >
        <ListCardsImage
          cards={newsRelatedCustom.post}
          isFeed
        />
      </Feed>
    );
  }
  return (
    <Feed
      marginTop="zero"
      marginBottom="zero"
      title="news"
      labelLink="Vedi Tutte"
      url={newsArchive.slug}
      hasBackground
      hasPadding
      handleArchiveClick={handleArchiveClick}
    >
      <ListCardsImage
        cards={relatedNews}
        isFeed
      />
    </Feed>
  );
};

const WrappedFeed = connect(null)(FeedContainer);

export const POST_QUERY = graphql`
  query postTemplate($slug: String!, $topicSlug: String!) {
    allDatoCmsNews (
      limit: 3,
      filter: {
        topic: {
          slug: {
            eq: $topicSlug
          }
        }
        slug: {
          ne: $slug
        }
      },
      sort: {fields: publicationDate, order: DESC}
    ) {
      nodes {
        slug
        title
        paragraph
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
            sizes: "(max-width: 1920px) 300px"
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        topic {
          id
          slug
          name
          categoryNews {
            id
            slug
            name
          }
        }
        publicationDate
      }
    }
    datoCmsNewsArchive {
      slug
    }
    datoCmsNews(slug: {eq: $slug}) {
      id
      slug
      title
      paragraph
      publicationDate
      author
      seoMetaTags {
        tags
      }
      visualComposer {
        ...CenteredNumbers
        ...Download
        ...FullImage
        ...MediumImage
        ...Numbers
        ...Quote
        ...Spacing
        ...SliderImagesGallery
        ...Paragraph
        ...VideoEmbed
        ...DataVisualization
      }
      topic {
        id
        name
        slug
        categoryNews {
          id
          name
          slug
        }
      }
      format {
        name
        slug
        id
      }
      model {
        apiKey
        name
      }
      coverImage {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
          sizes: "(max-width: 1920px) 100vw, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      coverImageMobile {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
          sizes: "(max-width: 767px) 100vw, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      documents {
        title
        size
        format
        url
        originalId
      }
      newsRelated {
        id
        model {
            apiKey
        }
        labelLink
        marginTop
        marginBottom
        title
        urlArchive
        post {
          id
          coverImage {
            alt
            fluid(
              imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
              sizes: "(max-width: 1920px) 300px"
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          publicationDate
          title
          slug
          topic {
            id
            name
            slug
          }
        }
      }
      widgetCard {
        id
        title
        description
        colorTheme
        email
        phone
        iconImageModularContent {
          ... on DatoCmsIcon {
            id
            iconType
          }
          ... on DatoCmsImage {
            id
            imageType {
              alt
              url
            }
          }
        }
        buttonModularContent {
          labelButton {
            value
          }
          url
        }
      }
    }
  }
`;

export default SinglePostTemplate;
