import React, { useState, useRef, useLayoutEffect } from 'react';
import Swiper from 'swiper';
import useMatchMedia, { useCheckResize } from '../../hooks/match-media';
import { InternalLink } from '../Link';
import Container from '../Container';
import useRemoveBackspaces from '../../hooks/hooks.strings';

const Feed = ({
  marginTop,
  marginBottom,
  title,
  labelLink,
  url,
  hasBackground,
  hasPadding,
  children,
  handleArchiveClick,
  withHighlight,
  isPublication
}) => {
  const [mySwiper, setMySwiper] = useState(null);
  const refSlider = useRef(null);
  const dictionary = {
    home: 'Notizie e Aggiornamenti',
    news: 'News ed Aggiornamenti',
    eventi: 'Appuntamenti & Eventi',
    pubblicazioni: 'Pubblicazioni Correlate',
    salaStampa: 'Comunicati stampa',
    storie: 'Ultime storie',
    localNews: 'News locali correlate'
  };

  const labelTitle = dictionary[title];
  /** Media rule to match on resize */
  const mediaToMatch = '(max-width: 1023px)';
  const [isMobile, setIsMobile] = useState(false);
  /** Using external HOOKS to listen window resize */
  useMatchMedia(setIsMobile, mediaToMatch);

  const handleRefresh = () => {
    if (isMobile && mySwiper) {
      mySwiper.refresh();
    }
  };

  useCheckResize(handleRefresh);

  useLayoutEffect(() => {
    if (!isMobile) {
      if (mySwiper) {
        /** Destroying instance and styles */
        mySwiper.destroy(true, true);
      }
    } else {
      /** Initializing Swiper */
      const swiper = new Swiper(refSlider.current, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        grabCursor: true,
        preloadImages: false,
        lazy: true,
        updateOnWindowResize: true,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        breakpoints: {
          768: {
            slidesOffsetBefore: window !== 'undefined'
              ? ((window.innerWidth - 720) / 2) + 62 : 0,
            slidesOffsetAfter: window !== 'undefined'
              ? ((window.innerWidth - 720) / 2) + 62 : 0
          }
        },
        on: {
          resize: () => {
            if (swiper.currentBreakpoint === '768') {
              swiper.params.slidesOffsetBefore = window !== 'undefined'
                ? ((window.innerWidth - 720) / 2) + 62 : 0;
              swiper.params.slidesOffsetAfter = window !== 'undefined'
                ? ((window.innerWidth - 720) / 2) + 62 : 0;
            }
          }
        }
      });
      setMySwiper(swiper);
    }
    return () => {
      if (mySwiper) {
        /** Destroying swiper when unmount the component */
        mySwiper.destroy(true, true);
      }
    };
  }, [isMobile]);

  const containerClassess = useRemoveBackspaces(
    `${isMobile ? 'swiper-container feed__slider-container' : 'feed__cards-container'}`
  );

  const listClasses = useRemoveBackspaces(
    `${isMobile ? 'swiper-wrapper feed__slider-wrapper' : 'feed__cards-list'}
    ${withHighlight ? 'feed__cards-list--highlight' : ''}`
  );

  return (
    <Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      className={`feed ${hasBackground ? 'feed--background' : ''} ${hasPadding ? 'feed--with-padding' : ''} ${isPublication ? 'feed--publication' : ''}`}
    >
      <header className="feed__title-container">
        {title && <h2 className="feed__title heading-md">{labelTitle}</h2>}
        {
          url && (
            <div className="feed__top-link">
              <InternalLink
                kind="secondary"
                label={labelLink}
                color="brand"
                url={`/${url}`}
                onClick={handleArchiveClick}
              />
            </div>
          )
        }
      </header>
      {
        children && (
          <div ref={refSlider} className={containerClassess}>
            <div className={listClasses}>
              {children}
            </div>
          </div>
        )
      }
      {
        url && (
          <footer className="feed__bottom-link">
            <InternalLink
              kind="secondary"
              label={labelLink}
              color="brand"
              url={`/${url}`}
              onClick={handleArchiveClick}
            />
          </footer>
        )
      }
    </Container>
  );
};

Feed.defaultProps = {
  labelLink: 'Vedi tutte'
};

export default Feed;
