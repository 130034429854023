import React from 'react';
import Img from 'gatsby-image';
import Link from '../TransitionLink';
import placeholderLogo from '../../assets/images/logo-placeholder.png';
import { CardTextInner } from '../CardText';

const CardImage = ({
  image,
  alt,
  label,
  title,
  date,
  slug
}) => {
  const fluid = {
    src: placeholderLogo
  };
  return (
    <Link
      to={`/media/${slug}/`}
      className="card-image__link"
    >
      <article className="card-image">
        <figure className={`card-image__image-container ${image ? '' : 'card-image__image-container--logo'}`}>
          {
            image
              ? <Img fluid={{ ...image, aspectRatio: 16 / 9 }} className="card-image__photo" alt={alt} />
              : <Img fluid={{ ...fluid, aspectRatio: 16 / 9, backgroundColor: '#1cabe2' }} className="card-image__logo" alt={alt} />
          }
        </figure>
        <CardTextInner
          label={label}
          title={title}
          date={date}
        />
      </article>
    </Link>
  );
};

export default CardImage;
